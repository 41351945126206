import { z } from 'zod'

export const FormDataSchema = z.object({
  boolean: z.enum(['true', 'false']).transform((v) => v === 'true'),
  optionalString: z
    .string()
    .trim()
    .optional()
    .transform((v) => v || undefined),
  number: z.coerce.number(),
  numberList: z
    .string()
    .transform((v) => v.split(',').filter(Boolean).map(Number)),
  stringList: z.string().transform((v) => v.split(',').filter(Boolean))
})
